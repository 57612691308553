import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
// import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { getContentUrls } from '../../utils/lessons';
// import { Container, Button } from 'react-bootstrap';
import "./courseBoard.css"
import "../curriculums/lessons/view.css"
const ViewArea = ({Lesson}) => {
  // const [pdfNumPages, setPdfNumPages] = useState(null);
  // const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [content, setContent] = useState([]);

  const { lesson } = useSelector(({ view }) => {   
    return { lesson: view?.lesson };
  });

  const getURLS = async () => {
    try {

      const res = await getContentUrls(!Lesson?lesson.content:Lesson.content);
      setContent(res.data.newContent);
      console.log(res);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
      getURLS();
    
  }, [lesson]);

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setPdfNumPages(numPages);
  // };

  // const preventRightClick = (e) => {
  //   e.preventDefault();
  // };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
console.log('content',content)
  return (
    <div className='view-area-cont' onContextMenu={preventContextMenu}>
      <h2>{lesson?lesson?.name:Lesson?.name}</h2>

      {content.length ? (
        <>
          {content.map((c, i) => {
            if (c.type.split('/').includes('image')) {
              return (
                <div key={i} onContextMenu={preventContextMenu}>
                  <hr />
                  <img
                    src={c.url}
                    alt="lesson cover"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              );
            } else if (c.type.split('/').includes('video')) {
              return (
                <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu}>
                  <hr />
                  {/* <ReactPlayer url={c.url} controls width="100%" height="auto" /> */}
                  <video controls controlsList="nodownload" width="100%" height="auto">
              <source src={c.url} type={c.type} />
              Your browser does not support the video tag.
            </video>
                </div>
              );
            } else if (c.type.split('/').includes('pdf')) {
              return (
                <div key={i} style={{ marginBottom: '20px',width:'100%' }} onContextMenu={preventContextMenu}>
                  {/* <Document file={c.url} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pdfPageNumber} />
                  </Document>
                  <p>
                    Page {pdfPageNumber} of {pdfNumPages}
                  </p>
                  <Button
                    onClick={() => setPdfPageNumber((prevPage) => Math.max(prevPage - 1, 1))}
                  >
                    Previous Page
                  </Button>
                  <Button
                    onClick={() => setPdfPageNumber((prevPage) => Math.min(prevPage + 1, pdfNumPages))}
                  >
                    Next Page
                  </Button> */}
 <div class="pdf-container">
 <iframe style={{backgroundColor:'#fff'}} src={`${c.url}`} width="100%" height="1200px" />
    {/* <object class="pdf-object" data={c.url} type="application/pdf">
        <embed src={c.url} type="application/pdf" width={'200vw'} />
    </object> */}
</div>
                   <div>
 </div>
                </div>
              );
            }
          })}
        </>
      ) : (
        <>
          <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
        </>
      )}
    </div>
  );
};

export default ViewArea;

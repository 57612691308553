import React, { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { getLessonsByIDS } from '../../utils/lessons';
import './courseBoard.css'
import NavigationBar from './navigationBar';
import LessonsTaps from './lessonsTaps';
// import MultimediaViewer from '../curriculums/lessons/view';
import { Set_Lesson } from '../../actions/curriculums/view';
import { Button } from 'react-bootstrap';
import { RiMenuFoldFill } from "react-icons/ri";
import { RiMenuUnfoldFill } from "react-icons/ri";
import TestTaps from '../curriculums/tests/testTaps';
import ViewArea from './viewArea';
import ViewTest from '../curriculums/tests/viewTest';

function CourseBoard() {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [showTest, setShowTest] = useState(false)
  const [isCompressed, setIsCompressed] = useState(false);
  const handleToggleMinimize = () => {
    setIsCompressed((prev) => !prev);
  };
  const { course,lesson,user } = useSelector(({ view,loggedUser }) => {
    return { course: view?.course,lesson:view?.lesson,user:loggedUser };
  });
  // console.log(user)
  // FETCH LESSONS
  const fetchLessons = async () => {
    try {
      setLoading(true);
      const IDS = course?.packData.selectedLessons;
      const res = await getLessonsByIDS({ lessonsIDS: IDS });
      setMessage({ case: res.data.case, message: res.data.message });
      setLessons(res.data.lessons);
      setLoading(false);
    } catch (err) {
      console.log(err.message);
      setMessage(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLessons();
  }, []);

  const dispatch =useDispatch();
  useEffect(()=>{
    if(!lesson&&lessons){
      dispatch(Set_Lesson(lessons[0]))
    }
  return ()=>{
    dispatch(Set_Lesson(null))
  }
  },[lessons])

  return (
    <>
      <NavigationBar/>
    <div className='board-cont'>
      {/* Column for Less on Tabs */}
    <Button style={{float:'left',width:'50px'}}  className={'compress-btn'} variant="secondary" onClick={handleToggleMinimize}>
          {isCompressed ? <RiMenuFoldFill size={20}/> : <RiMenuUnfoldFill/>}
        </Button><br/>
      <div style={{ flex: isCompressed ? '0 0 auto' : '1', width:isCompressed?'0px':'200px' }} className={`board-taps`}>
<br/><br/>
        <h2>{lesson?lesson.name:''}</h2>
        <LessonsTaps setShowTest={setShowTest} lessons={lessons}/>
        {/* Render your lesson tabs here */}
      </div>

      {/* Column for Lesson Content */}
      <div className={`board-content ${isCompressed?'board-content-compress':'board-content-full'}`} >
        {!showTest?
        <ViewArea Lesson={lesson?lesson:null}/>:
        <ViewTest userID={user.user._id}/>}
        {/* <TestTaps testIDS={lesson?lesson.testIDS:[]}/> */}
      </div>
    </div>
    </>
  );
}

export default CourseBoard;

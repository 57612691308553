import React, { useEffect, useState } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { urlBase } from '../../../utils/API';
import { createRequest } from '../../../utils/packagesRequests';
import { useNavigate } from 'react-router-dom';
import { Wallet_Number } from '../../../constants';

function Subscripe({ packData, user }) {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShowModal(false);

  const handleShow = () => {
    if(!user.auth){
        alert('يجب تسجيل الدخول قبل الاشتراك');
        navigate('/login')
    }
    else{
        setShowModal(true);}
    }
// console.log(user)
  const handleImageChange = (e) => setImage(e.target.files[0]);

  const handleSubmit = async () => {
    try {
      if (!image) return alert('برجاء ارفاق صورة لرسالة او ايصال الدفع ');
       setLoading(true)
      const formData = new FormData();
      formData.append("file", image);
      const uploadingResponse = await axios.post(`${urlBase}/api/upload-file-locally`, formData);

      const request = {
        packData,
        userID:user?.user._id,
        packID: packData._id,
        receipt: uploadingResponse.data.data
      };

      const res = await createRequest(request);
      setLoading(false)
      setMessage({ case: res.data.case, message: res.data.message });
      alert(res.data.message);
      handleClose();
    } catch (err) {
      console.log(err.message);
      setLoading(false)
      setMessage({ case: false, message: err.message });
    }
  };

  useEffect(()=>{
    setTimeout(()=>{
       setMessage(null)
    },5000)
  },[message])
  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        اشترك الآن
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{packData?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>
          برجاء دفع مبلغ {packData.price} ج.م على محفظة الرقم الموضح 
          ثم قم برفع صورة من ايصال او رسالة الدفع 
          </Modal.Title>
          <Modal.Dialog dir={'ltr'} style={{color:'green', fontWeight:'900', textAlign:'center'}}>
          {Wallet_Number}
          </Modal.Dialog>
          <label for="fileInput" class="custom-file-upload"> اختر صورة ايصال او رسالة الدفع</label><br/>
          <input type="file" id="fileInput" onChange={handleImageChange} placeholder='ارفع ايصال الدفع'  />
          {image && <img src={URL.createObjectURL(image)} alt="Chosen" style={{ display: 'block', marginTop: '10px', maxWidth: '80%' }} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            إلغاء
          </Button>
          {!loading?<Button variant="primary" onClick={handleSubmit}>
            اشترك
          </Button>: <span>جارى الطلب ... </span>}
        </Modal.Footer>
      </Modal>

      {message && (
        <Alert variant={message.case ? 'success' : 'danger'} onClose={() => setMessage(null)} dismissible>
          <Alert.Heading>{message.case ? 'Success' : 'Error'}</Alert.Heading>
          <p>{message.message}</p>
        </Alert>
      )}
    </div>
  );
}

export default Subscripe;

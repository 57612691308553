import axios from 'axios';
import {urlBase} from './API';

// create new class 
export const newClass = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/create-new-class`,
        data:data //{name , levelId, branchId}
    })
}

// add students 
export const addStudentsToClass = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/add-students-class`,
        data:data// {students,id}
    })
}
// change class active state
export const changeClassActiveState = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/deactivate-class`,
        data:data // {id,active}
    })
}
// get classes 
export const getClasses = ()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/get-classes`,
        
    })
}
//get student classes 

export const getClassesByStudent = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/get-classes-by-student`,
        data:data // {studentId,packID}
    })
}

// create meeting room
//{classId}
export const createMeetingRoom = (data)=>{
    return axios({
        method:'post',
        url:`${urlBase}/api/classes/${data.classId}/teachers`,
        data:data
    })
}
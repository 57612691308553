import React from 'react';
import {NavLink,useParams,Outlet } from 'react-router-dom';
import Avatar from '../../assets/profile-avatar.png';
import './profile.css';
import {FaPhotoVideo} from 'react-icons/fa';
function Profile({user}) {

  return (<>
  
        {/* {user.role==='teacher'&&<div className='teach-taps'>
            </div>} */}
    <div className='profile-cont'>
<div className='profile-details'>
    <div className='prof-avatar'>
        <NavLink id='profnnav' to={`/user/${user._id}/profile/upload`}>
<img src={user & user.personalImage?user.personalImage:Avatar}/>

        </NavLink>
   <div>
    <div className='prof-det'>
    <span >{user&& user.username}</span>
    <span >{user&& user.email}</span>
</div>
{user.role==='teacher'&&<div className='prof-upload'>
<NavLink id='profnnav' to={`/user/${user._id}/profile/upload-video`}>
  
  Video upload
   <FaPhotoVideo/>
        </NavLink>
</div>}
   </div>
   <Outlet/>
    </div>
    <form>
        <div className='prof-form-wrap'>
            <div>

            <label htmlFor="prfoilna1" id={'profnam1'}>Full name</label>
            <br/><input id='prfoin1' type='text' name='name'/>
            </div>
            <div>
            <label htmlFor="prfoin2" id='profilna2'>Email</label>
            <br/>
            <input id='prfoin2' type='email' name='email'/>
            </div>
            <div>
            <label htmlFor="prfoin3" id='profilna3'>password</label>
<br/>
            <input id='prfoin3' type='password' name='password'/>
            </div>

<div>
<label htmlFor="prfoin4" id='profilag'>age</label>

<input id='prfoin4' type='number' name=''/>
<label htmlFor="prfoin5" id='profilelan'>language</label>

<input id='prfoin5' type='text' name=''/>

</div>
        </div>
        <input id='prfoin6' type='submit' value='save'/>
    </form>
</div>

        </div>
        </>
  )
}

export default Profile
import React, { useEffect, useState } from "react";
// import Col from "../styled-components/col";
// import Contain from "../styled-components/contain";
import {OLP_Logo as Logo} from "../../constants.js";
import "./header.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NavButton from "../styled-components/navButton";
import { Log_Out } from "../../actions/login&register";
import Button from "../styled-components/button";
// import { Set_Lang } from "../../actions/translation.js";
// import LangSwitcher from "../translation/languageSwitcher";
import {AiOutlineMenu} from 'react-icons/ai';
import SideNave from './mobileMenu.js'


//
const publicPages = [
  { name: "الرئيسية", to: "" },
  { name: "  البرامج الدراسية", to: "/subscription" },
  { name: "من نحن؟", to: "/about" },
 // { name: "teachers", to: "/teachers" },
  

];
const studentPages = [
  { name: "الرئيسية" ,to: "" },
  { name: "الحجز", to: "/booking" },
  //{ name: "الحجوزات", to: "/course" },
  { name: "الرسائل", to: "/messages" },
  { name: "meeting", to: "/call" },
];
const teacherPages = [
  { name: "الرئيسية", to: "" },
  { name: "جدول الحصص", to: "/teacher-table" },
  { name: "غرفة اجتماع", to: "/call" },
  { name: " تحميل صورة", to: "/upload" },
  { name: "تحميل  فيديو", to: "upload-video" },
  { name: "الحجوزات", to: "/bookings" },
  { name: "الرسائل", to: "/messages" },
];
const adminPages = [
  ...publicPages,
  { name: " لوحة التحكم ", to: "/admin-dashboard" },
];
//
function Header({menuColor}) {
  const user = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const onClick = () => {
    dispatch(Log_Out());
    navigate("/");
  };
  
 
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (

    <header id="header " className="public_bg" >
      {windowSize.width>1000 ?
      <> 
      
      <List menuColor={menuColor} />
      
    
      <ul className="action">
        <li className="list-btn">
          {user.auth ? (
            <a href="/" onClick={onClick}>
              <Button
                name={"تسجيل خروج"}
              
                width="100px"
                id='logout-btn'
                className={"login-register-btn"}
              />
            </a>
          ) : (
            <NavButton
              to="/login"
              name="دخول /  تسجيل"
              width="170px"
              perfix = 'header-call-us login-register-btn'
            />
          )}
        </li>

       {/* <li className="list-btn">
          <LangSwitcher iconColor='#fff' color='#fff' marginRight='5px'/>
        </li>*/}
      </ul>
      </>:<HeaderMobile/>} 
     {user.auth&& <div id='bck-dash'>
        <NavLink to={`/user/${user._id}`} id='dashboard-button' className={"login-register-btn"}>
          لوحة التحكم
        </NavLink></div>}
    </header>
  );
}
//-----List Component-------
const List = ({menuColor}) => {
  const user = useSelector(mapStateToProps);

  const pages = () => {
    if (user.role === "student") {
      return studentPages;
    } else if (user.role === "teacher") return teacherPages;
    else if (user.role === "Admin") return adminPages;
    else {
      return publicPages;
    }
  };
  return (
    <nav >
    <ul className="list" >
      <li className="list-btn">
        <a href="/">
          {" "}
          <img src={Logo} alt="منصة المستشار التعليمية" className="header-logo" />
        </a>
      </li>
      {publicPages.map((p, i) => (
        <li className="" key={i} >
          <NavLink id={'header'+i} style={{color:menuColor}} className="list-btn" to={p.to}>
            {p.name}
          </NavLink>
        </li>
      ))}
    </ul>
    </nav>
  );
};


// mobile view

 function HeaderMobile (){

  const user = useSelector(mapStateToProps);
  const [openSide, setOpenSide] = useState(false);//
  const pages = () => {
    if (user.role === "student") {
      return studentPages;
    } else if (user.role === "teacher") return teacherPages;
    else if (user.role === "Admin") return adminPages;
    else {
      return publicPages;
    }
  };
  //
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onClick = () => {
    dispatch(Log_Out());
    navigate("/");
  };
  return(
    <>
     <a href="/">
          {" "}
          <img src={Logo} alt="Osbash  Learning Platform" width="60px" className="main-logo" />
        </a>
        <AiOutlineMenu color='#FFFFFF' size='40px' onClick={()=>{setOpenSide(true)}}/>
        <SideNave open={openSide} onClose={()=>{setOpenSide(false)}} logo={Logo} main={[...publicPages,{name:"تسجيل / دخول",to:"/login"}]}/>
    <br/>
    </>
  )
 }
const mapStateToProps = ({ loggedUser, language }) => {
  return { ...loggedUser.user, auth: loggedUser.auth };
};
export default Header;

import React from 'react'
import "./input.css"
function Input({type,name,placeholder,label,id}){
    return(
      <>
      <div className='input-comp-cont'>
      <label htmlFor={id?id:name}>{label?label:placeholder}</label>
      <input
      className='input-comp'
      name={name}
      type={type}
      placeholder={placeholder}
      id={id?id:name}
      />
      </div>
      </>
    )
  }

export default Input
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Add_Room } from '../../../actions/class';
import { Alert, Button, Modal } from 'react-bootstrap';

function NewRoom({Class}) {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [message , setMessage] = useState(null)
    const handleAddRoom = () => {
        setShowModal(true); // Show the modal when Add Room button is clicked
    };

    const confirmCreateRoom =async () => {
        try{
         const res = await dispatch(Add_Room({classId:Class._id}));
         setMessage(res.data);
            // setShowModal(false); // Close the modal after creating a new room
        }
        catch(err){
            console.warn(err)
            setMessage({case:false, message:`${err.message}`})
        }
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal when Cancel or backdrop is clicked
    };

    useEffect(()=>{
        if(message){
         setTimeout(()=>{

             setMessage(null)
         },5000)
        }
    },[message])
    return (
        <div>
            <Button variant="primary" onClick={handleAddRoom}>
                Add Room
            </Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Room Creation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to create a new room?
                </Modal.Body>
                <Modal.Footer>
                    {message?<Alert variant={message.case?'success':'danger'}>{message.message}</Alert>:<></>}
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmCreateRoom}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default NewRoom;

import React from 'react';
import { useLocation } from "react-router-dom";

// import backG from '../../assets/blue-header.png';
function BlueHeader({title,subTitle}) {
  const style = {
    display:'block',
    // backgroundImage:'url(../../assets/blue-header.png)'

  }
  const location = useLocation();
// console.log(title.split(' ').join(''))
  return (
    <header className='blue-h' style={style}>
        <h1 id={'bluetsb'+title.split(' ').join('')}>
          {title?title:'Title'}
          </h1>
        
        <p id={'blue-subt'}>{subTitle}</p>
    </header>
  )
}

export default BlueHeader
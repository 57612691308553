import React from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Inetialize_Teachers } from '../../actions/teachers';
// import Contain from '../styled-components/contain';
// import Col from '../styled-components/col';
// import axios from "axios";
import Whyus from './whyus.js'
// import URL_BASE from '../../../.env'
//import { Container } from 'react-bootstrap'
// import Header from './header'
import Statistics from './statistics.js';
import HeroSection from './heroSection'
// import TeacherVideo from './teacherVideo';
import TeachersHome from './teachers.js';
import "./home.css"
import Joinus from './join-us.js';
import StartLearning from './startLearning.js';
// require('dotenv').config()
function Home() {
const dispatch = useDispatch();
useEffect(()=>{
  dispatch(Inetialize_Teachers())
},[])

const {teachers,lang} = useSelector(mapStateToProps)


  
  return (
   <>
   
   
   <HeroSection/>
    <Whyus/> 
   <Statistics/> 
  { 
 // <TeachersHome teachers={teachers}/>
  }
   <Joinus/> 

   {/* <StartLearning lang={lang}/> */}
   </>
   
    
  ) 
}
function mapStateToProps({users,translation}){// get all teachers from users
  const teachers =users.teachers.length? users.teachers.filter((t)=>t.active==true):[]
  const lang = translation.current;
  return {teachers,lang}
}
export default Home

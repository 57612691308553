import React, { useState, useRef, useEffect } from "react";
import "./login.css";
import {  useSelector } from "react-redux";
// import LoginForm from "./loginForm";
import {  useNavigate,NavLink } from "react-router-dom";
// import Logo from '../../assets/logo.png'
import "./newlogin.css";
import Header from '../userDashboard/Header.js';
import BlueHeader from '../publicPages/blueHeader.js'
import {Sign_Image as LogIMG, platformName} from '../../constants.js'
import { useDispatch } from "react-redux";
import serializeForm from "form-serialize";
import Footer from '../Home/footer'
import UAParser from 'ua-parser-js';

import { BoxLoading,WaveLoading,SolarSystemLoading		 } from 'react-loadingg';
import { 
    Register_Admin,
    Register_Teacher,
    Register_Student,
    
    logIn,
  } from "../../actions/login&register";

  const parser = new UAParser();
const result = parser.getResult();
  let browserInfo = {
    ...result.os,
    ...result.cpu
  };


const LoginComponent = ({type}) => {

  const [mode, setMode] = useState("login");
  const [loading,setLoading]= useState(false);
  const navigate = useNavigate();
  const formRef = useRef();
  const user = useSelector(mapStateToProps)
  const [message,setMessage] = useState()
  const dispatch = useDispatch();

  //------------login func----------
const signUp = async (e) => {
  try {
    e.preventDefault();
    setLoading(true)
    const data = serializeForm(formRef.current, { hash: true });
    data.role =type;
    data.username=data.name;
    const res = await dispatch(Register_Student(data));
    setLoading(false);
    setMessage(res.data.message)
    if(res.case){
      setMode('login');
    }
    const loginData = {email:data.email,password:data.password,device:browserInfo}
  // data.device=browserInfo;
    const loginRes = await dispatch(logIn(loginData));

      setMessage('login successfully')
      setLoading(false)
      // setMessage('')
      navigate(`/user/${loginRes.user._id}`)      
    
  }
   catch (err) {
    setMessage('حدث خطا اثناء محاولة تسجيل المستخدم برجاء المحاولة مرة اخرى')
    console.log(err);
    setLoading(false)
  }
};

//-------Login Function--------
const logInFunc = (e) => {

  try {
    e.preventDefault();
    setLoading(true)
    const data = serializeForm(formRef.current, { hash: true });
    data.device=browserInfo;
    dispatch(logIn(data)).then(res=>{
      setLoading(false)
      setMessage('')
      if(res.auth ){
        if(res.user.role==='Admin'){
          setMessage('login successfully')
          // navigate('/admin-dashboard')  
          navigate(`/user/${res.user._id}`)      
        }
        else{

          setMessage('login successfully')
          navigate(`/user/${res.user._id}`)      
          
          
        }
      }
     else if(res.case ===false) return setMessage(res.message)

else if(!res.auth){
  setMessage('email or password is not correct')
}

setTimeout(() => {
  setMessage('')
}, 5000);
})}
catch(e){
  console.log(e.message)
  setMessage(e.message)
  setLoading(false)

}
}
//--------------------------------- 
 
  
  const swichLogin =(e)=>{
e.preventDefault();
const newMode = e.target.value;

setMode(newMode);
setMessage('');
  }

  //--------------- handel check box
  const [selectedCheckbox, setSelectedCheckbox] = useState('');

  const handleCheckboxChange = (event) => {
    setSelectedCheckbox(event.target.id);
  };
if(!loading){
  return (
    <>

      <div>
    <Header />
<BlueHeader title='تسجيل الدخول'/>
         {/* <div
          className={`form-block-wrapper form-block-wrapper--is-${mode}`}
          >

        </div> */}
        <section className='log-body'>
  <div className='lognav-cont public_bg'>
<button
value={'login'}
className={mode==='login'? 'buttons_bg':'t-bg'}
id='lognav2'
 onClick={(event)=>swichLogin(event)}>دخول</button>
<button 
className={mode==='signup'? 'buttons_bg':'t-bg'}
 value={'signup'}
id='lognav3' 
onClick={(event)=>swichLogin(event)}>تسجيل</button>
  </div>
<div className='log-form-cont'>
<form 
onSubmit={mode==='login'?logInFunc:signUp}
className='log-form-new' ref={formRef}>
<span id='log-res-mess' >{message}</span>
  <span id = 'log-sp-1'>{mode==='login'?'مرحبا فى صفحة تسجيل الدخول':'مرحبا بك فى صفحة التسجيل الجديد'}</span>
  {mode==='signup'&&<>
  <label htmlFor='sign1' id='signsp1'> الاسم كاملا</label>
  <input type='text' name='name' id='signinp1'/>
  </>}
<label id='logla1' htmlFor='login1'>
  البريد الالكترونى
</label>
<input type='text' name='email' id='login1'/>
<label id='logla2' htmlFor='login2'>
  الرقم السرى
</label>
<input type='password' name='password' id='login2'/><br/>
{mode==='signup'&&<>
  <label htmlFor='sign2' id='signsp2'> اعد ادخال الرقم السرى</label>
  <input type='password' name='password2' id='signinp2'/>
  </>}
{/* {mode==='login'&&<NavLink id='logfor1' to={'/password/reset'}> </NavLink>} */}
{mode==='signup'&&<>
<div className='checkbox-area'>
  <span id='signsp3'>النوع</span>
<label htmlFor='gender' id='signlab4'>
<input type='checkbox' name='gender'
value="male"
checked={selectedCheckbox === 'signrad1'}
onChange={handleCheckboxChange}
id='signrad1'/>ذكر</label>
<label htmlFor='gender'  id='signlab5'>
<input  type='checkbox' name='gender'
value="female"
checked={selectedCheckbox === 'signrad2'}
onChange={handleCheckboxChange}
id='signrad2'/>انثى</label>
</div>
<label id='signlab6'>
  العمر
  <input type='number' name='age' />
</label>
</>
}
<input id='logSub1' className="buttons_bg" type='submit' value={mode==='login'?'دخول':'تسجيل'}/>
</form>
</div>
<div className='log-img-cont'>
  <img src={LogIMG} alt={platformName}/>
</div>
        </section>
        {/* <section className={`form-block form-block--is-${mode}`}>
        <a href='/'> <img src={Logo} alt="AGA ENGLISH" width='100px'/></a>
          <header className="form-block__header">
            <h1>{mode === "login" ? "Welcome back!" : "Sign up"}</h1>
            <div className="form-block__toggle-block">
              <span>
                {mode === "login" ? "Don't" : "Already"} have an account? Click
                here &#8594;
              </span>
              <input
                name="form-toggler "
                id="form-toggler"
                type="checkbox"
                onClick={() => toggleMode()}
                value={mode}  
                className='toggler'
              />
              <label className='toggler-label'  htmlFor="form-toggler"></label>
              
             
            </div>
          </header>
          <LoginForm mode={mode} setMode={setMode} type='student' />
          
        </section> */}
      </div>
   <Footer/> 

    </>
  )
      }
  else{
    return(<>
      <SolarSystemLoading	></SolarSystemLoading	>
      {/* <p>Loading...</p> */}
      </>
    )
  }

}

function mapStateToProps({loggedUser}){
  return loggedUser
}

 export default LoginComponent;

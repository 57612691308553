import React from 'react'
import './whyus.css';
import v1 from '../../assets/why1-icon.svg';
import v2 from '../../assets/why2-icon.svg';
import v3 from '../../assets/why3-icon.svg';
import v4 from '../../assets/why4-icon.svg';

function Whyus() {
    const icons = [{icon:v1,title:'منصة شاملة',dis:'كل شئ يخص العملية التعليمية فى مكان واحد'},
    {icon:v2,title:'مواعيد مرنة',dis:'نقوم بتنسيق أوقات التعلم وفقًا لجدولك الزمني. احجز الدروس في الوقت الذي تريد أن تتعلم فيه.'},
    {icon:v3,title:'اسعار مناسبة للجميع',dis:'اسعار تناسب جميع الفئات الاجتماعية, مع ضمان الجودة العلمية.'},

    {icon:v4,title:'معلم موثوق',dis:'اكثر من 2000 تجربة تعليمية ناجحة '}
]
  return (
    <section className='why-us '>
     <div className='why-us-rect'>
        <h1 id='why1'>
        لماذا تتعلم عبر منصتنا؟
        </h1>
        <p id='why2'>
        لأنه نموذج للتعليم الآمن والفعال والمتطور وبأسعار معقولة .        </p>
        <div className='why-us-icons '>
         {icons.map((c,i)=><div className='why-icon-item' key={i}>
            <i><img src={c.icon}/></i>
            <h4 id={'whyh4'+i}>{c.title}</h4>
            <span id={'whyspan'+i}>{c.dis}</span>
         </div>)}
         {/* <img src={v1} width='100%' alt=''/> */}
        </div>
     </div>
    </section>
  )
}

export default Whyus
import React, { useEffect, useState } from "react";
import Social from "./social";
import "./footer.css";
import Rights from "./rights";
import { NavLink } from "react-router-dom";
// import Logo from "../../assets/logo.png";
import {OLP_Logo as Logo,phone,email,footerText} from "../../constants"
function Footer() {
  const publicPages = [
    { name: "الرئيسية", to: "" },
    { name: "البرامج الدراسية", to: "/subscription" },
    // { name: "teachers", to: "/teachers" },
    { name: "من نحن؟", to: "/about" },
  ];
  const quickLinks = [
    { name: "من نحن؟", to: "/about" },
    // { name: "call us", to: "/" },
    { name: " سياسة الخصوصية ", to: "/privacy" },
  ];

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <footer className="footer ">
      <div className="footer-logo-menu public_bg">
        <div className="footer-logo-disc">
          <a href="/" rel="home-page">
            {" "}
            <img src={Logo} alt="logo" width="197px" />
          </a>
          <p className="footer-disc" id="foot1">
{footerText}      
          </p>
        </div>
        <div className="footer-menu">
          <ul className="pages">
            <h2 className="menu-title" id="foot2">
              من نحن؟
            </h2>
            {publicPages.map((p, i) => (
              <li key={i}>
                <NavLink id={"footer" + i} to={p.to}>
                  {p.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="pages">
            <h2 className="menu-title" id="foot4">
              روابط سريعة
            </h2>
            {quickLinks.map((p, i) => (
              <li key={i}>
                <NavLink id={"footer2" + i} className="footer-tab" to={p.to}>
                  {p.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="pages">
            <h2 className="menu-title" id="foot5">
              معلومات التواصل
            </h2>
            <li>
              <a id="foot6" href={`tel:${phone}`} rel="contact-number">
                {phone}
              </a>
            </li>
            <li>
              <a
                id="footmail"
                href="#"
                rel="contact-email"
              >
{email}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Social />
      <Rights />
    </footer>
  );
}

export default Footer;

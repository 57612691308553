import React from 'react';
import './Joinus.css';
import cal from '../../assets/calendar_icon.svg';
import search from '../../assets/search.svg';
import fill from '../../assets/Fill-4.svg';
import hchoose from '../../assets/home-choose.jpg';
import { join_Sec_Text,join_Sec_Title } from '../../constants';
import {NavLink} from 'react-router-dom';
function JoinUs() {
  return (
    <>
    <section className='home-j-u-s public_bg'>
        <div className='j-l'>
        </div>
        <div className='j-r'>
            <h1 id='join1'>
            {join_Sec_Title}
            </h1>
            <p id='join2'>
          {join_Sec_Text}
            </p>
            <div className=' hero-btns'>
    <NavLink id='join3' to='/login' className='btn1 buttons_bg'> تسجيل</NavLink>
    <NavLink id='join4' to='/login' className='btn2 buttons_bg'> دخول</NavLink>
  </div>

        </div>
    </section>
    {/* <section className='choose-teach'>
      <img className='choose-teach-img' src={hchoose} alt='choose-teacher' />
      <div className='icon-list'>
        <h2 id='join5'>
          
        </h2>
        <span id='join6'>How does the platform work?</span><br/>
        <span><i><img src={search} alt='search-icon'/></i>
        <span id='join7'>
        Choose your favorite teacher
        </span>
         </span><br/>
        <span>
          <i><img src={cal} alt=''/></i>
          <span id='join8'>
          Select the appropriate time
          </span>
           </span><br/>

        <span>
          <i><img src={fill} alt=''/></i> 
          <span id='join9'>
          Learn a lot of language skills
</span>
          </span><br/>

      </div>
      <div className=' btns-c'>
    <NavLink id='join10' to='/teachers' className='btn1-c'> Free trial</NavLink>
    <NavLink id='join11' to='/teachers' className='btn2-c'> Book now</NavLink>
    
  </div>
    </section> */}
    </>
  )
}

export default JoinUs
import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {Set_Course} from '../../actions/curriculums/view';
import { getClassesByStudent } from '../../utils/class';

function ProgramCard({program,user}) {
  const [pClass,setPClass] = useState(null);

  const dispatch = useDispatch()


  const getClass = async ()=>{
    try{
      const res =await getClassesByStudent({studentId:user._id,packID:program.packID});
      setPClass(res.data.Class[0])
    }
    catch(err){
        console.log(err)
    }
  }
  useEffect(()=>{
    getClass()
  },[program])
  console.log('program',program)
  return (
    <Row key={program._id} style={programCardStyle}>
          <div style={cardContentStyle}>
            <h2 style={programTitleStyle}>{program?.packData?.name}</h2>
            <NavLink 
            onClick={()=>{
             dispatch(Set_Course(program))
            }} 
            to={`/course-board/${program._id}`} className={"buttons_bg"} style={followLinkStyle}>
              متابعة
            </NavLink>
            {pClass&& pClass.roomId?<NavLink to={`/meeting-room/${user._id}/${pClass?.roomId}`}
               style={followLinkStyle} className={"buttons_bg"}>غرفة الاجتماعات</NavLink>:<></>}
          </div>
        </Row>
  )
}
const containerStyle = {
    maxWidth: '800px',  // Adjust the maximum width for responsiveness
    margin: '0 auto',
  };
  
  const programCardStyle = {
    display: 'flex',
    flexDirection: 'column',  // Stack items vertically on small screens
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    padding: '20px',
    margin: '10px 0',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };
  
  const cardContentStyle = {
    textAlign: 'center',
  };
  
  const programTitleStyle = {
    color: '#333',
    marginBottom: '10px',
  };
  
  const followLinkStyle = {
    color: '#fff',
    textDecoration: 'none',
    padding: '10px 15px',
    borderRadius: '5px',
    display: 'inline-block',
    transition: 'background-color 0.3s ease',
    margin:'0 10px 0 10px'
  };
  
  const titleStyle = {
    fontSize: '1.5rem',  // Adjust font size for smaller screens
  };
export default ProgramCard
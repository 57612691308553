import React, { useState } from "react";
import SideModal from "../sideModal/sideModal.js";
import NavButton from "../styled-components/navButton";
import "./sideNave.css";
import { MdLanguage } from "react-icons/md";
import MainMenu from "./mainMenu";
import GetText from '../translation/getText.jsx'
function SideNave({ open, onClose, logo,main }) {
//   const main = [
//     { name: "home", path: "home", icon: "" },
//     { name: "about", path: "about", icon: "" },
//     { name: "contact", path: "contact", icon: "" },
//     { name: "store", path: "store", icon: "" },
//   ];
  const language = [{ name: "EN" }, { name: "AR" }];
  const [show, setShow] = useState(false);
  return (
    <SideModal
    open={open}
    onClose={onClose}
    width="50vw"
    height="100vh"
    icon={logo}
    >
      <GetText>
    
     <MainMenu lang={true} main={main}/>
    </GetText>
    </SideModal>
  );
}

export default SideNave;

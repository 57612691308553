import React from "react";
import "./heroSection.css";
import { NavLink } from "react-router-dom";
// import Hero from '../../assets/hero.png';
import { useSelector } from "react-redux";
import ltrBack from '../../assets/hero.png';
// import rtlBack from '../../assets/Mr-ahmed.png';
import { Hero_Section_Image as rtlBack , hero_section_title,hero_section_text } from "../../constants";

function HeroSection() {
  const { current } = useSelector(direction);
const style={
  backgroundImage:current==='en'?`url(${ltrBack})`:`url(${rtlBack})`,
  backgroundPosition:current==='en'?'100%':'10%',

}


  return (
    <div className="hero-sec hero_Section" style={style}>
      {/* <div className="hero-overLay"></div> */}
      <div className="left-hero">
        <p className="hero-para1 glass-element" id="hero1">
{hero_section_title}
        </p>
        <p className="hero-para2 glass-element" id="hero2" >
       {hero_section_text}
        </p>
        <div className="hero-btns">
          <NavLink id="hero3" to="/login" className="btn1 buttons_bg ">
            {" "}
            تسجيل الدخول
          </NavLink>
         { //<NavLink id="hero4" to="teachers" className="btn2">
            //{" "}
            //Free trial
          //</NavLink>
          }
        </div>
      </div>
      <div className="right-hero"></div>
    </div>
  );
}

function direction({ translation }) {
  return { current: translation.current };
}
export default HeroSection;

import React from 'react'
import MessagesCont from '../message/messages';
import {useSelector} from 'react-redux'
function MessagesAdmin() {
    const {user,uss} = useSelector(mapStateToProps);
  return (
 
    <MessagesCont recived={user.recived} sent={user.sent} users={uss}/>
  )
}
function mapStateToProps ({general,users}){
const user = general.currentP;
const uss = user.role==='student'?users.teachers:users.students
return {user,uss}
}
export default MessagesAdmin
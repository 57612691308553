import {create_Levele,delete_Level,get_All_Levels,updateLevel} from '../../utils/API';

export const CREATE_LEVELE = 'CREATE_LEVELE';
const createLevele = (level)=>{
   return { 
    type:CREATE_LEVELE,
    level
   }
}

export const Create_Levele = (data)=>{
    return (dispatch)=>{
       return create_Levele(data).then(res=>{
            if(res.data.case){
                dispatch(createLevele(res.data.level))
            }
            return res
        })
    }
}

export const DELETE_LEVEL ='DELETE_LEVEL';

const deleteLevel = (id)=>{
    return {
        type:DELETE_LEVEL,
        id
    }
}
// Delete level
export const Delete_Level =(id)=>{
    return (dispatch)=>{
       return delete_Level(id).then(res=>{
        if(res.data.case){
            dispatch(deleteLevel(id))
        }
        return res
       })
    }
}
// get levele

export const INETIALIZE_LEVELS = 'INETIALIZE_LEVELS'; 

const inetializeLevels = (levels)=>{
    return {
        type:INETIALIZE_LEVELS,
        levels
    }
}

export const Inetialize_Levels = ()=>{
    return (dispatch)=>{
        return get_All_Levels().then(res=>{
            if(res.data.case){
                dispatch(inetializeLevels(res.data.levels))
            }
            return res
        })
    }
}

// edit level

export const EDIT_LEVEL = "EDIT_LEVEL";

const editLevel = (level)=>{
   return {
    type:EDIT_LEVEL,
    level // only properties wanted to be updated with id sent in an object
   }
}

export const Update_Level = (level)=>dispatch=>{
    return updateLevel(level).then(res=>{
        if(res.data.case){
            dispatch(editLevel(res.data.level))
        }
        return res
    })
}

import OLP_Logo from 
"./assets/future-teachers-logo.jpeg"
//'./assets/olp-logo.png';
import OLP_White_Logo from './assets/future-teachers-logo.jpeg'//'./assets/logo-white.png';
import Hero_Section_Image from './assets/home-choose.jpeg'//"./assets/herortl.png"
import Sign_Image from 
'./assets/confirm.png' 
import statisticsImage from "./assets/about.png"

//"./assets/logo-white.png"
export const platformName = "Future Teachers"
const year = new Date().getFullYear();
export const Rights_Text = `All_Rights_Reserved_For_Future_Teachers@${year}`
export const Rights_Text2 =`
جميع حقوق الملكية محفوظة لمنصة Future Teachers    @ ${year}
`
 //" OLP.OSBASH © Copyright 2023. All Rights Reserved";
export const {whatsapp , phone,email} =
 {
    whatsapp : "+201279320831" //"+201551661850"
  , phone : "+201279320831",
    email:
    "am12345678911rasom@gmail.com"
    //'info@osbash.com'
};

export const Wallet_Number = "+2 01013951207"
export const hero_section_title = ` Future Teachers`;
export const hero_section_text = `منصة Future Teachers 
منصة لكل المُعلمين تابعة لمنظمة منتدى التربويين ،تم إنشاؤها في 2024 
تهدف إلى تدريب المُعلمين وتهيأتهم إلى إدارة الصف الدراسي بكفاءة عالية وإستغلال كل الإمكانات التربوية والعلمية والتكنولوجية لتحقيق عملية التعلم ونقل العلم بكفاءة عالية ونستهدف من خلال المنصة كل من هو مهتم بمجال التدريس سواء اثناء دراسته الأكاديمية أو أثناء ممارسته لمهنة التدريس بشكل عملي`

// "منصة OLP  التعليمية مقدمة من شركة اوسباش كخطوة حقيقية للمساهمة فى التحول الرقمى للعملية التعليمية"
export const join_Sec_Title = `انضم الى منصة Future Teachers الآن وطور مهاراتك كمعلم`
export const join_Sec_Text = `منصة Future Teachers 
منصة لكل المُعلمين تابعة لمنظمة منتدى التربويين ،تم إنشاؤها في 2024 
تهدف إلى تدريب المُعلمين وتهيأتهم إلى إدارة الصف الدراسي بكفاءة عالية وإستغلال كل الإمكانات التربوية والعلمية والتكنولوجية لتحقيق عملية التعلم ونقل العلم بكفاءة عالية ونستهدف من خلال المنصة كل من هو مهتم بمجال التدريس سواء اثناء دراسته الأكاديمية أو أثناء ممارسته لمهنة التدريس بشكل عملي`

export const start_Sec_title = `ابدأ الآن مع Future Teachers`;
export const start_Sec_Text =`سجل الدخول - احجز البرنامج التعليمى المناسب لك - ابدا التعلم
 واضمن النجاح والشهرة كمعلم.`
export const footerText = `نهدف إلى رفع كفائة المُعلمين وتعزيز قدراتهم من عدة جوانب "مهارية ،علمية ،تربوية" كما نهدف ايضًا إلى تأهيلهم لسوق العمل وتزويد خبراتهم المهنية ومساعدتهم على مواكبة التطورات الرقمية المستمرة التي تطرأ على مجال التعليم`
export {OLP_Logo,OLP_White_Logo,Hero_Section_Image,Sign_Image ,statisticsImage};
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddMaterial from './addMaterial';
import { Update_Material, Delete_Material } from '../../../actions/curriculums/materials';
import { Table, Button, Modal, NavLink } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import AddLesson from '../lessons/addLesson';
import ViewLessons from '../lessons/viewLessons'
import AddTestBtn from '../tests/AddTestBtn';
function Materials() {
  const materials = useSelector(state => state.materials);
  const dispatch = useDispatch();
  const [deleteMaterialId, setDeleteMaterialId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteConfirmation = (materialId) => {
    setDeleteMaterialId(materialId);
    setShowDeleteModal(true);
  };

  const handleDeleteMaterial = () => {
    // Dispatch the delete material action
    dispatch(Delete_Material(deleteMaterialId));
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };
 
  const handleCloseDeleteModal = () => {
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };

  return (
    <div>
      <AddMaterial />

      <Table responsive>
        <thead>
          <tr>
            <th>المادة</th>
            <th>كود المادة</th>
            <th>اضافة درس</th>
            <th>الدروس</th>
            <th>اضافة اختبار</th>
            <th>تعديل</th>
            <th>حذف</th>
          </tr>
        </thead>
        <tbody>
          {materials.map(material => (
            <tr key={material._id}>
              <td>{material.name}</td>
              <td>{material.code}</td>
              <td>
             <AddLesson materialCode={material.code}/>
              </td>
              <td><ViewLessons materialCode={material.code}/></td>
             <td>
              <AddTestBtn 
                type={'material'}
                testFor={material._id}
                />
              </td>
              <td>
                {/* Add your edit functionality or button here */}
                {/* Example: <Button variant="primary" onClick={() => handleEditMaterial(material.id)}>Edit</Button> */}
                
              </td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteConfirmation(material._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this material?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteMaterial}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Materials;

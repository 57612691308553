import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';

// import Peer from "peerjs";

// import Words from './components/quiz/words'
import Rank from './components/quiz/Rank'

//import { Register_Admin,Register_Teacher,Register_Student,intializeLogIn,logIn } from "./actions/login&register";
import Home from "./components/Home/Home";
import Admin from "./components/Admin/Admin";
// import RegisterComponent from "./components/Register&Login/RegisterComponent";
import LoginComponent from "./components/Register&Login/login";
import { intializeLogIn } from "./actions/login&register";
import { Inetialize_Teachers } from "./actions/teachers";
// import {Inetialize_Url_Base} from "./actions/urlBase"
// import Teacher from "./components/Teacher/Teacher";
import Booking from "./components/booking/booking";
import Dashboard from "./components/dashboard";
import Table1 from "./components/Teacher/table1";
import Upload from "./components/upload/upload";
import UploadVideo from "./components/upload/video";
import NewProduct from "./components/Admin/newProduc";
import UploadImage from "./components/Admin/upload";
import Products from "./components/Admin/products";
import SubCategory from "./components/Admin/subCategory.";
import ChooseTeacher from "./components/booking/chooseTeacher";
import Category from "./components/booking/category";
import ChooseDate from "./components/booking/chooseDate";
import TeachersList from "./components/Admin/teachers";
import Bookings from "./components/Teacher/bookings";
import StudentMessages from "./components/Student/studentMessages.jsx";
import Course from "./components/Student/courses.jsx";
import Confirm from "./components/booking/confirm.jsx";
import Thanks from "./components/booking/Thanks.jsx";
import { Inetialize_Students } from "./actions/students";
import Students from "./components/Admin/students.jsx";
import Accounts from "./components/Admin/accounts/accounts.jsx";
import Appointments from "./components/Admin/appointments.jsx";
//import {Register} from "./components/Register&Login/Register.jsx"
// import { inetialize_QS } from './actions/questions';
import TeacherMessages from "./components/Teacher/teacherMeassages.jsx";
import RegisterTeacher from "./components/Register&Login/registerTeacher.jsx";
import NewDisscountCode from "./components/Admin/disscount/newCode.jsx";
import { urlBase } from "./utils/API";
import EditProduct from "./components/Admin/editProduct.jsx";
import TeachersPage from "./components/publicPages/teachers";
// import Meeting from "./components/meeting/meeting";
import About from "./components/publicPages/about";
import SendMessage from "./components/hiWhats/sendMessage.jsx";
// import Translat from "./components/translation/translation";
import { InetializeTranslation } from "./actions/translation";
import UserDashboard from "./components/userDashboard/userDashboard";
import Profile from "./components/userDashboard/profile";
import Messages from "./components/userDashboard/messages";
import Manage from "./components/userDashboard/manage";
import Privacy from './components/publicPages/privacy';
import GetText from './components/translation/getText.jsx';
import { Inetialize_Products } from "./actions/product";
import { Inetialize_Appointments } from './actions/booking.js'
import MessagesAdmin from './components/Admin/messages';
import DisscountList from './components/Admin/disscount/disscountList'
import ResetPass from "./components/Register&Login/resetPass";
import Alllevels from "./components/curriculums/levels/AlllevelsComponent.js";
import LevelsMainPage from "./components/curriculums/levels/levelsMainPage.js";
import Materials from "./components/curriculums/materials/materials";
// import { Get_Materials } from "./actions/curriculums/materials";
import Packages from "./components/curriculums/packages/packages.js";
import { Get_All_Packages } from "./actions/packages.js";
import MultimediaViewer from "./components/curriculums/lessons/view.js";
import AddTest from "./components/curriculums/tests/addTest.js";
import Programs from "./components/Student/programs.js";
import CourseBoard from "./components/Student/courseBoard.js";
import ViewTest from "./components/curriculums/tests/viewTest.js";
import StudyPrograms from "./components/publicPages/programs/subscription.js"
import BuyRequests from "./components/Admin/requests/requests.js";
import Classes from "./components/Admin/classControl/classes.js";
import Meeting from './components/meetingSystem/meeting.js';

function App() {
  const dispatch = useDispatch();
  const { data, translation } = useSelector(mapStateToProps);
  const { userId, userToCall, roomId } = useSelector(callDetails);
  // console.log('env',process.env.MONGO)

  const getPacks = async () => {
    try {
      await dispatch(Get_All_Packages());
    } catch (err) {
      console.warn(err.message);
    }
  };

  useEffect(() => {
    dispatch(InetializeTranslation())
    dispatch(intializeLogIn());
    dispatch(Inetialize_Teachers());
    dispatch(Inetialize_Students());
    dispatch(Inetialize_Products());
    dispatch(Inetialize_Appointments());
    dispatch(Get_All_Packages())
    getPacks()
  }, []);

  return (
    <BrowserRouter>
      {/* <Translat/> */}
      <Routes>
        {/* <Route path="/add-test" element={<AddTest/>} /> */}

        <Route path="test" element={<UserDashboard />}>
          <Route path="profile" element={<Profile user={data} />} />
          <Route path="messages" element={<Messages />} />
          <Route path="class/management" element={<Manage />}></Route>
          {/* <Route path='messages' /> */}
        </Route>

        <Route path="/login" element={<GetText><LoginComponent type='student' /></GetText>} />
        <Route path="/password/reset" element={<GetText><ResetPass /></GetText>} />

        <Route path="/register/teacher" element={<GetText><RegisterTeacher /></GetText>} />
        <Route path="/privacy" element={<GetText><Privacy /></GetText>} />
        
        {/* view course */}
        
        {data.auth && 
        <Route path ='/course-board/:id' element={<CourseBoard/>}/>
        }
        {/* ----------------------- */}
        {data.auth?<Route path="/meeting-room/:id/:roomId" element={<Meeting/>}/> :<></>}

        <Route path="/" element={<Dashboard />}>
          {/* ------------------------Public Routes--------------------------- */}
          <Route path="" element={<Home />} />
          <Route path="*" element={<Home />} />

          <Route path="teachers" element={<GetText><TeachersPage /></GetText>} />
          <Route path="about" element={<About />} />
          <Route path= "subscription" element = {<StudyPrograms/>}/>
          <Route path="booking" element={<GetText><Booking /></GetText>}>
            <Route path="" element={<Category />} />
            {(data.role === "student") & (data.auth === true) && (
              <>
                <Route path="choose-teacher" element={<ChooseTeacher />} />
                <Route path="choose-date" element={<ChooseDate />} />
                <Route path="confirm" element={<Confirm />} />
              </>
            )}
            <Route path="thanks" element={<Thanks />} />
            {/* <Route path="tab-pay" element={<TabForm />} /> */}
          </Route>

          {/* ---------------------------Teacher Routes--------------------------- */}

          {/* ------------------------Admin Routes------------------------- */}

          {/* ----------------------------Student Routes--------------------------------- */}
        </Route>


        {(data.role === "student") & (data.auth === true) && (
          <>
            {/* <Route index element={<Teacher/>}/>  */}
            <Route path="user/:id" element={<GetText><UserDashboard user={data} /></GetText>}>
              <Route
                path=""
                element={
                  <Manage
                    taps={[
                      { name: "My Courses", path: "" },

                    ]}
                  />
                }
              >
                <Route path="" element={<Programs user={data}/>} />

                <Route path="profile" element={<GetText><Profile user={data} /></GetText>} />
                <Route
                  path="messages"
                  element={<StudentMessages user={data} />}
                />
              </Route>
            </Route>

          </>
        )}

        {/* ---------------------------------Tachers -------------*/}

        {(data.role === "teacher") & (data.auth === true) && (
          <Route path={"user/:id"} element={<UserDashboard user={data} />}>
            <Route
              path=""
              element={
                <Manage
                  taps={[
                    { name: "weekly course schedule", path: "schedual" },
                    { name: "Appointments", path: "" },
                  ]}
                />
              }
            >
              <Route path="schedual" element={<Table1 />} />
              <Route path="" element={<Bookings />} />
            </Route>
            <Route path="profile" element={<Profile user={data} />}>
              <Route path="upload" element={<Upload />} />
              <Route path="upload-video" element={<UploadVideo />} />
            </Route>
            <Route path="messages" element={<TeacherMessages user={data} />} />
          </Route>


        )}
        {/* ------------------------------------------------ */}
        {/* <Route
          path="call"
          element={
            <Meeting userId={userId} userToCall={userToCall} roomId={roomId} />
          }
        /> */}

        {(data.role === "Admin") & (data.auth === true) && (
          <>
            <Route path="user/:id" element={<GetText><UserDashboard user={data} /></GetText>}>
              <Route path="" element={<BuyRequests />} />
              {/* <Route path="" element={<Manage />} /> */}
              <Route path="profile" element={<Profile user={data} />} />
              {/* <Route path="messages" element={<MessagesAdmin />} /> */}
              <Route path ="messages" element={<TeacherMessages user={data}/>}/>
              <Route path="new-product" element={<NewProduct />} />
              <Route path="cover" element={<UploadImage />} />
              <Route path="products" element={<Products user={data} />} />
              <Route path="sub-category" element={<SubCategory />} />
              <Route path="teachers-list" element={<TeachersList />} />
              <Route path='classes' element={<Classes/>}/>
              <Route path="students" element={<Students />} />
              <Route path="accounts" element={<Accounts />} />
              <Route path="list/disscount" element={<GetText><DisscountList urlBase={urlBase} /></GetText>} />
              <Route
                path="new/disscount" 
                element={
                  <NewDisscountCode
                    urlBase={urlBase}
                    afterSubmitFunc={(data) => {
                      // console.log(data);
                    }}
                  />
                }
              />
              <Route path="edit/product" element={<EditProduct />} />
              <Route path="whats-message" element={<SendMessage />} />
              <Route path='new-level' element={<LevelsMainPage />} />
              <Route path='materials' element={<Materials />}>
                {/* <Route path="add-lesson/:id" element={<AddLesson/>}/> */}
              </Route>
              <Route path="view-lesson" element={<MultimediaViewer />} />
              <Route path="add-test" element={<AddTest />} />
             
              <Route path="packages" element={<Packages />} />

            </Route>

            <Route path="admin-dashboard" element={<Admin />}>
              <Route path="new-product" element={<NewProduct />} />
              <Route path="cover" element={<UploadImage />} />
              <Route path="products" element={<Products />} />
              <Route path="sub-category" element={<SubCategory />} />
              <Route path="teachers-list" element={<TeachersList />} />
              <Route path="students" element={<Students />} />
              <Route path="accounts" element={<Accounts />} />
              <Route path="appointments" element={<Appointments />} />
              <Route
                path="new/disscount"
                element={
                  <NewDisscountCode
                    urlBase={urlBase}
                    afterSubmitFunc={(data) => {
                      // console.log(data);
                    }}
                  />

                }
              />
              <Route path="edit/product" element={<EditProduct />} />
              <Route path="whats-message" element={<SendMessage />} />
              {/* Educational Content  */}
              <Route path='new-level' element={<Alllevels />} />
            </Route>
          </>
        )}
        {/* <Route path="/register" element={<RegisterComponent/>}/>   */}
        <Route path="/exam" element={<ViewTest userID={data._id}/>} />
        <Route path="/rank" element={<Rank />} />
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = ({ loggedUser, translation }) => {
  const data = { ...loggedUser.user, auth: loggedUser.auth };
  return { data, translation };
};
function callDetails({ meeting, loggedUser }) {
  if (!meeting.roomId) {
    return { userId: "nouser", userToCall: "", roomId: "gfdsd" };
  } else {
    const users = meeting.roomId.split("&");
    if (loggedUser.user.role === "teacher") {
      return {
        userId: loggedUser.user._id,
        userToCall: users[1],
        roomId: meeting.roomId,
      };
    }
    if (loggedUser.user.role === "student") {
      const returned = {
        userId: loggedUser.user._id,
        userToCall: users[0],
        roomId: meeting.roomId,
      };
      return returned;
    }
  }
}
export default App;

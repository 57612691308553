import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import { GetMaterialsByIds } from '../../../utils/materials';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { CiSquareRemove } from 'react-icons/ci';
import { Create_New_Package } from '../../../actions/packages';
import { BoxLoading } from 'react-loadingg';
import Loading from '../../loadingEffect';

function AddPackage({currency}) {
  const [name, setName] = useState(null)
  const [materials, setMaterials] = useState([]);
  const [allLessons, setAllLessons] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [price , setPrice] = useState(0);
  const [selectedValues, setSelectedValues] = useState({
    levelId: '',
    materialCode: '',
    selectedLessons: [],
  });
  const [loading ,setLoading] = useState(false);
  const [message,  setMessage] = useState(null)
  const dispatch = useDispatch();

  const { levels } = useSelector(({ levels }) => ({ levels }));

  const resetAllValues = ()=>{
    setName(null);
    setMaterials([]);
    setLessons([]);
    setAllLessons([]);
    setPrice(0);
    setSelectedValues({levelId: '',
    materialCode: '',
    selectedLessons: [],
  })
  }
  const onChangeLevel = async (levelId) => {
    try{
      if(!levelId || levelId.trim()==='') return ;
    const selectedLevel = levels.find((level) => level._id === levelId);
    const res = await GetMaterialsByIds({ IDs: selectedLevel.materialIDS });
    const { materials } = res.data;
    setMaterials(materials);
    setSelectedValues({
      levelId,
      materialCode: '',
      selectedLessons: [],
    });
  }catch(err){
    console.log(err.message)
  }
  };

  const onChangeMaterial = async (materialCode) => {
    const res = await getLessonsOfSpecificMaterial({ code: materialCode });
    const { lessons } = res.data;
    setLessons(lessons);
    setAllLessons(lessons);
    setSelectedValues({
      ...selectedValues,
      materialCode,
      selectedLessons: [],
    });
  };

  const onChangeLessons = (id) => {
    const updatedLessons = lessons.filter((lesson) => lesson._id !== id);

    setSelectedValues({
      ...selectedValues,
      selectedLessons: [...selectedValues.selectedLessons, id],
    });
    setLessons(updatedLessons);
  };

  const onRemoveLesson = (id) => {
    const removedLesson = selectedValues.selectedLessons.find((lessonId) => lessonId === id);
    const updatedSelectedLessons = selectedValues.selectedLessons.filter((lessonId) => lessonId !== id);

    setLessons([...lessons, allLessons.find((lesson) => lesson._id === removedLesson)]);
    setSelectedValues({
      ...selectedValues,
      selectedLessons: updatedSelectedLessons,
    });
  };

  const onChangePrice = (price)=>{
      setPrice(price)
  }
  
  // SUBMITE
  const handleSubmit =async (e) => {
    try{
      
    e.preventDefault();
    if(!name||!price||!selectedValues.selectedLessons.length) return alert('من فضلك قم باكمال البيانات المطلوبة!')
    setLoading(true)
    const data = {...selectedValues,price,name}
    const res= await dispatch(Create_New_Package(data));
    resetAllValues();
    setMessage(res.data.message);
    // Dispatch actions or perform further actions here
    setLoading(false)  
  }
    catch(err){
      console.error(err);
      setMessage(err.message);
      setLoading(false)
    }
  };

  return (
    <Container className='loading-parent' style={{border:'0px solid red',width:'100%'}}>
      <h3>اضافة برنامج جديد</h3>
    
      {loading?<Loading/>:<></>}
      <Form onSubmit={handleSubmit}>
        <Row>

        <Col md={12}>
            <Form.Group controlId="materialSelect">
              <Form.Label>اسم الباقة </Form.Label>
              <Form.Control
                type="text"
                placeholder='ادخل اسم الباقة'
                style={{ width: '150px' }}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group controlId="levelSelect">
              <Form.Label> المستوى</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeLevel(e.target.value)}
              >
                <option value="">اختر المستوى</option>
                {levels.map((level) => (
                  <option key={level._id} value={level._id}>
                    {level.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="materialSelect">
              <Form.Label>السعر {currency?currency:'ج.م'}</Form.Label>
              <Form.Control
                type="number"
                placeholder='سعر الباقة'
                style={{ width: '150px' }}
                onChange={(e) => onChangePrice(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="materialSelect">
              <Form.Label>المواد</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeMaterial(e.target.value)}
              >
                <option value={null}> اختر المادة</option>
                {materials.map((material) => (
                  <option key={material.code} value={material.code}>
                    {material.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group controlId="lessonsSelect">
              <Form.Label>الدروس</Form.Label>
              <Form.Control
                as="select"
                style={{ width: '150px' }}
                onChange={(e) => onChangeLessons(e.target.value)}
              >
                <option value={null}>Select lessons</option>
                {lessons.map((lesson) => (
                  <option key={lesson._id} value={lesson._id}>
                    {lesson.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ marginTop: '20px' }}>
            <div>
              <strong>Chosen Lessons:</strong>
              {selectedValues.selectedLessons.map((selectedLesson) => (
                <div key={selectedLesson}>
                  <span>
                    {allLessons.find((lesson) => lesson._id === selectedLesson)?.name}
                  </span>
                  <CiSquareRemove
                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                    size={25}
                    onClick={() => onRemoveLesson(selectedLesson)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Button type="submit" style={{ marginTop: '20px' }}>
          Submit
        </Button>
      </Form>
    </Container>
  );
}

export default AddPackage;

import React, { useState } from 'react';
import { Form, FormControl, FormGroup, FormLabel, Button, FormCheck, Container, Alert } from 'react-bootstrap';
import './tests.css';
import { useSelector } from 'react-redux';
import { createTest } from '../../../utils/tests';

function AddTest() {

  const [message, setMessage] = useState('');
  const [testName, setTestName] = useState('');
  const [totalDegree, setTotalDegree] = useState(0); // Added totalDegree state
  const [questions, setQuestions] = useState([
    {
      question: '',
      degree: '',
      choices: [
        { value: '', correct: false },
        { value: '', correct: false },
        { value: '', correct: false },
        { value: '', correct: false },
      ],
    },
  ]);

  const [alertVariant, setAlertVariant] = useState(null);

  const handleTestNameChange = (e) => {
    setTestName(e.target.value);
  };

  const handleTotalDegreeChange = (e) => {
    setTotalDegree(Math.abs(e.target.value));
  };

  const handleQuestionChange = (e, questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].question = e.target.value;
      return updatedQuestions;
    });
  };

  const handleDegreeChange = (e, questionIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].degree =Math.abs(e.target.value);
      return updatedQuestions;
    });
  };

  const handleChoiceChange = (e, questionIndex, choiceIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].choices[choiceIndex].value = e.target.value;
      return updatedQuestions;
    });
  };

  const handleRadioChange = (questionIndex, choiceIndex) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].choices = updatedQuestions[questionIndex].choices.map(
        (choice, i) => {
          return { ...choice, correct: i === choiceIndex };
        }
      );
      return updatedQuestions;
    });
  };

  const addQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        question: '',
        degree: '',
        choices: [
          { value: '', correct: false },
          { value: '', correct: false },
          { value: '', correct: false },
          { value: '', correct: false },
        ],
      },
    ]);
  };

  const deleteLastQuestion = () => {
    if (questions.length > 1) {
      setQuestions((prevQuestions) => prevQuestions.slice(0, -1));
    }
  };

  // get test target id
  const { testFor } = useSelector(({ view }) => {
    return { testFor: view?.testFor };
  });

  // submit test
  const submitTest = async () => {
    try {
      const testData = {
        test: {
          name: testName,
          tot_degree: totalDegree, // Include totalDegree in the request
          questions: questions,
        },
        testFor,
      };
      const res = await createTest(testData);
      setMessage(res.data.message);
      setAlertVariant(res.data.case ? 'success' : 'danger');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      {message && (
        <Alert variant={alertVariant} onClose={() => setMessage('')} dismissible>
          {message}
        </Alert>
      )}

      <FormGroup>
        <FormLabel>Test Name:</FormLabel>
        <FormControl type="text" value={testName} onChange={handleTestNameChange} />
      </FormGroup>

      <FormGroup>
        <FormLabel>Total Degree:</FormLabel>
        <FormControl type="number" value={totalDegree} min={1} onChange={handleTotalDegreeChange} />
      </FormGroup>

      <br />
      <div className='align-evenly'>
        <Button onClick={addQuestion} variant="primary">
          Add Question
        </Button>
        <Button onClick={deleteLastQuestion} variant="danger" style={{ marginLeft: '10px' }}>
          Delete Last Question
        </Button>
      </div>

      {questions.map((question, questionIndex) => (
        <Form key={questionIndex} style={{ marginTop: '20px' }}>
          <FormGroup>
            <FormLabel style={{ backgroundColor: 'red', color: '#fff', padding: '5px', borderRadius: '5px' }}>
              Question {questionIndex + 1}:
            </FormLabel>
            <FormControl type="text" value={question.question} onChange={(e) => handleQuestionChange(e, questionIndex)} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Question Degree:</FormLabel>
            <FormControl type="number" value={question.degree} min={1} onChange={(e) => handleDegreeChange(e, questionIndex)} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Choices:</FormLabel>
            {question.choices.map((choice, choiceIndex) => (
              <div key={choiceIndex}>
                <FormControl
                  type="text"
                  value={choice.value}
                  placeholder={`Choice ${choiceIndex + 1}`}
                  onChange={(e) => handleChoiceChange(e, questionIndex, choiceIndex)}
                />
                {/* Modify the label below or remove it */}
                <FormCheck
                  type="radio"
                  label={``} //{/* Add your label text here */}
                  className='test-radio'
                  checked={choice.correct}
                  onChange={() => handleRadioChange(questionIndex, choiceIndex)}
                />
              </div>
            ))}
          </FormGroup>
        </Form>
      ))}

      {/* Add your submit button */}
      <Button onClick={submitTest} variant="success" style={{ marginTop: '20px' }}>
        Submit Test
      </Button>
      <hr />
      <hr />
    </Container>
  );
}

export default AddTest;

import React, { useEffect, useState } from 'react';
import AddClass from './addClass';
import { Get_Classes } from '../../../actions/class';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import MenuSelection from '../requests/menuSelection';
import DeactivateClass from './deactivateClass';
import AddStudents from './addStudents';
import SendMessage from '../../message/sendMessage';
import SendMessageToGroup from '../../message/sendMessageToGroup';
import ClassStudents from './classStudents';
import NewRoom from './newRoom';
import { NavLink } from 'react-router-dom';

function Classes() {
    const [status, setStatus] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Get_Classes());
    }, [dispatch]);

    const { classes,packages,user } = useSelector(({ classes,packages,loggedUser }) => ({
        classes,packages,user:loggedUser.user
    }));
 
    const getPackageByID = (id)=>{
     const pack = packages.find(p=>p._id===id);
    //  console.log('pack',pack)
     return pack
    }

    // Filter classes based on active/inactive status
    const filteredClasses = status ? classes.filter(cls => cls.active) : classes.filter(cls => !cls.active);
    return (
        <div>
            <AddClass />
            <MenuSelection
                status={status}
                setStatus={setStatus}
                name1={'الفصول الحالية'}
                name2={'الفصول غير المفعلة'}
            />
            <Table responsive>
                <thead>
                    <tr>
                        <th>الفصل</th>
                        <th>البرنامج الدراسى</th>
                        <th>عددالطلاب</th>
                        <th>ارسال رسالة</th>
                        <th>اضافة طلاب</th>
                        <th>الطلاب</th>
                        <th>انشاء غرفة اجتماع</th>
                        <th>دخول الاجتماع</th>
                        <th>تعطيل الفصل</th>

                    </tr>
                </thead>
                <tbody>
                    {filteredClasses.map((cls, index) => (
                        <tr key={index}>
                            <td>{cls.name}</td>
                            <td>{packages&& cls.packID && getPackageByID(cls.packID)?.name}</td>
                            <td>{cls.studentsIDS.length}</td>
                            <td><SendMessageToGroup to={cls.studentsIDS}/></td>
                            <td><AddStudents Class={cls}/></td>
                            <td><ClassStudents Class={cls}/></td>
                            <td><NewRoom Class={cls}/></td>
                            <td>{cls.roomId?<NavLink to={`/meeting-room/${user._id}/${cls?.roomId}`}>دخول</NavLink>:<>لا يوجد</>}</td>
                            <td> <DeactivateClass classId={cls._id}/></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default Classes;

import React from 'react'
import './statistics.css'
import { useSelector } from "react-redux";
import {statisticsImage as com } from '../../constants.js'
import s1 from  '../../assets/stat1.png'
import s2 from  '../../assets/stat2.png'
import s3 from  '../../assets/stat3.png'
import Bar from '../../assets/Bar chart line.png'
function Statistics() {
    const icons = [{icon:s1,title:'+15 مؤلف',dis:' موثوق'},
    {icon:s2,title:'1000+ طالب',dis:'مشترك'},
    {icon:s3,title:'+10000 حصة',dis:'منجزة'},
]
const { current } = useSelector(direction);

const style = {
  position: 'absolute',
  right:current==='en'&& '0',
  left:current==='ar'&&'0',
  top: '-150px',
  transform:current==='ar'&&'scaleX(-1)', 
}

  return (
    <section className='statistics public_bg'>
      <div className='statistic-img'>
        <img className='com' style={style} src={com} alt='OLP-statistics'/>
       </div >
       <div className='chart-bar'>
        <img src={Bar} alt='olp-chart' />
        <span id='statistics1'>احصائيات </span>
       </div>
        <div className='statistics-icons'>
        {icons.map((c,i)=><div key={i} className='icon-item'>
          <img src={c.icon} width='50px'/>
          <h5 id={'stath'+i}>
            {c.title}
          </h5>
          <span id={'statsp'+i}>
            {c.dis}
          </span>
        </div>)}
        </div>
    </section>
  )
}
function direction({ translation }) {
  return { current: translation.current };
}
export default Statistics

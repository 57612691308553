import React from 'react'
import BlueHeader from './blueHeader';
import Footer from '../Home/footer.jsx';
import Header from '../userDashboard/Header'
import Contain from '../styled-components/contain.jsx'
import Col from '../styled-components/col.jsx'
import './privacy.css'
function Privacy() {
  return (
    <>
    <Header/>
    <BlueHeader title='Privacy and  policy'/>
   {/* <section> */}
   <Contain perfix='privacy-cont'>
    <Col perfix='privacy-sub-cont' width='80%' dir='column' align='start' >
   <h2 id='prv1'>
   Privacy Policy - Aga Education Platform
   </h2>
   <h3 id='prv2'>
   Privacy and confidentiality of information statement
   </h3>
   <p id='prv3'>
   We take very seriously the privacy of our website visitors and the security of any personal information that website visitors may provide. We believe in your right to know what kind of information the site collects, how it is protected and used, and under what circumstances it is disclosed. That is why we have prepared this privacy policy and published it on our website in order to help you understand the nature of the data that we collect about you when you visit our website and how we deal with this data.
   </p>
   <h4>
   Browseing site
   </h4>
   <p>
   This site does not aim to collect your personal data while browsing the site, but only the data provided by you will be used with your knowledge and of your own free will.
   </p>
    </Col>
   </Contain>
    <Footer/>
    </>
  )
}

export default Privacy
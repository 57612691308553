import React from 'react'
import {NavLink} from 'react-router-dom'
import LangSwitcher from "../translation/languageSwitcher"
function MainMenu({lang,main}) {
   
  return (
<>
<nav className="main-menu">
        <ul>
        <h5 id='mainm1'>الصفحات الرئيسية</h5>
          {main&&main.map((item, index) => 
            <li key={index}>
              <NavLink
                id={'mainmenu'+index}
                to={item.to}
                className="main-menu-item"
                style={({ isActive }) => ({
                  color: '#ffffff',
                  opacity: isActive ? 1 : "",
                  borderBottom:'1px solid',
                  marginBottom:'10px'
                })} 
              >
                {item.name}
              </NavLink>
            </li>
          )}
        </ul>
        <div className="lang-cont">
       {/*{lang&&<LangSwitcher/>}*/}
   
        </div>
      </nav>
</>
    )
}

export default MainMenu

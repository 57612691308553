import React,{useState,useRef} from 'react'
import "./newAccount.css"
import Modal from "../../modal/modal.js";
import Col from "../../styled-components/col";
import Contain from "../../styled-components/contain";
import { FcPlus } from "react-icons/fc";
import Input from "../../input/input";
import serializeForm from "form-serialize";
import { useDispatch } from "react-redux";
import { 
    Register_Admin,
    Register_Teacher,
    Register_Student,
  } from "../../../actions/login&register";
function NewAccount({type}) {
  const [open,setOpen] = useState(false);
  const form = useRef();
  const dispatch=useDispatch();
  //-----------Create New Account Function------
  const createNew =(e)=>{
    e.preventDefault();
    const data = serializeForm(form.current, { hash: true });
    data.role=type;
    const today= new Date()
    data.date=today.toISOString();
    if (type === "teacher") {
      dispatch(Register_Teacher(data));
       
      }
    else if (type === "student") {
        dispatch(Register_Student(data));
      }
    else if (type === "admin") {
        dispatch(Register_Admin(data));
      }
      setOpen(false)
  }
  return (
    <>
    <div className='fcplus-cont'>
        <FcPlus
          size="2em"
          onClick={() => setOpen(true)}
          style={{
            position: "absolute",
            // top: "10px",
            right: "5px",
            cursor: "pointer",
          }}
        />
        </div>
      <Modal
            open={open}
            onClose={() => setOpen(false)}
            width={"70vw"}
            height="60vh"
          >
            <form ref={form} onSubmit={createNew}>
              <Contain>
                <Col dir="column" fg="1" border="0px">
                  <Col
                    fg="1"
                    justify="space-around"
                    border="0px"
                    perfix="form-row-n"
                    width="100%"
                  >
                    <Input
                      name="fullname"
                      type="text"
                      placeholder="Full name"
                      label="Full name"
                    />
                    <Input
                      name="username"
                      type="text"
                      placeholder="Username"
                      label="Username"
                    />
                  </Col>
                  <Col
                    fg="1"
                    justify="space-around"
                    border="0px"
                    perfix="form-row-n"
                    width="100%"
                  >
                    <Input name="email" type="email" placeholder="Email" />
                    <Input
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                  </Col>
                  <Col
                    fg="1"
                    justify="space-around"
                    border="0px"
                    perfix="form-row-n"
                    width="100%"
                  >
                    <Input name="phone" type="text" placeholder="Phone" />
                  </Col>
                  <input type="submit" name="Save" placeholder="Save" className='subm'/>
                </Col>
              </Contain>
            </form>
          </Modal>
    </>
  )
}

export default NewAccount
import React from 'react'
import BlueHeader from './blueHeader';
import Contain from '../styled-components/contain.jsx';
import Col from "../styled-components/col.jsx";
import aboutIMG from '../../assets/about.png';
import  './about.css';
import abicon1 from '../../assets/about-future.png'
import {NavLink} from 'react-router-dom';
import sec2Ic1 from '../../assets/about1.svg'
import sec2Ic2 from '../../assets/about2.svg'
import sec2Ic3 from '../../assets/about3.svg'
import sec2Ic4 from '../../assets/about4.svg'
import sec3Ic1 from '../../assets/aboutsec31.svg';
import sec3Ic2 from '../../assets/aboutsec32.svg'
import sec3Ic3 from '../../assets/aboutsec33.svg'
import { platformName } from '../../constants.js';

function About() {
  return (
  <>
  <BlueHeader title='من نحن؟'/>
 <Contain perfix='about-sec1'>
    <Col perfix='absec1-st'  dir='column' justify='center'>
       <span className='a-b-s1-sp' id='ab1'>
         Future Teachers 
       </span>
        <h2 id='ab2'>
            اصنع مستقبلك كمعلم بنفسك! - أهدافنا
             
            </h2>

         <p id='ab3'>
            تهدف <a href='/'>Future Teachers </a>إلى رفع كفائة المُعلمين وتعزيز قدراتهم من عدة جوانب "مهارية ،علمية ،تربوية" كما تهدف ايضًا إلى تأهيلهم لسوق العمل وتزويد خبراتهم المهنية ومساعدتهم على مواكبة التطورات الرقمية المستمرة التي تطرأ على مجال التعليم<b>
            
        
            </b>
            <br/>
            <br/>
            <br/>
          <h2>
            ما هى <a href='/'>Future Teachers </a>?
          </h2>
          منصة لكل المُعلمين تابعة لمنظمة منتدى التربويين ،تم إنشاؤها في 2024 
          تهدف إلى تدريب المُعلمين وتهيأتهم إلى إدارة الصف الدراسي بكفاءة عالية وإستغلال كل الإمكانات التربوية والعلمية والتكنولوجية لتحقيق عملية التعلم ونقل العلم بكفاءة عالية ونستهدف من خلال المنصة كل من هو مهتم بمجال التدريس سواء اثناء دراسته الأكاديمية أو أثناء ممارسته لمهنة التدريس بشكل عملي
         <br/> <br/>
                  ومعد ايضا سلسلة التحدى فى  جغرافية التنمية
          والتى حققت اعلى مبيعات فى جغرافية التنمية للصف الثانى الثانوى الادبى 
         وذائعة الصيت على المواقع الخاصة بالانترنت مثل موقع  
        
           
           
         </p>
       <span id='ab4'>
        <i><img src={abicon1} alt={platformName}/></i>
       ضمان النجاح كمعلم      </span>
    </Col>
    {/* <Col perfix='absec1-en' border='0px'>
        <img  src={aboutIMG} alt='about-Mr-Mohamed-Sonbol'/>
    </Col> */}

 </Contain>
 <Contain perfix='about-sec2' >
  <Col width='100%' perfix='about-sec2-sub-cont'>
 <Col perfix='absec2-2'>
    <div className='absec2-icons'>
        <div className='absec2st'>
        <div className='absec2-icon'>
        <img src={sec2Ic1}/>
        <h4 id='absec2h4'>
        نبحث عن التطور دائما
        </h4>
      </div>
      <div className='absec2-icon'>
        <img src={sec2Ic3}/>
        <h4 id='absec2h4'>
        نسعى دائما للتميز
                </h4>
      </div>
        </div>
        <div className='absec2end'>

      <div className='absec2-icon'>
        <img src={sec2Ic2}/>
        <h4 id='absec2h4'>
      اسعارنا مميزة
                </h4>
      </div>
      <div className='absec2-icon'>
        <img src={sec2Ic4}/>
        <h4 id='absec2h4'>
        معنا نضمن لك النجاح كمعلم تربوى 
        </h4>
      </div>
        </div>
    </div>
    </Col>
  <Col perfix='absec2-1'>
    <span id='absec2-1'> منصة Future Teachers للتعلم عن بعد  </span>
<h3 id='absec2h3'>لماذا تتدرب معنا كمعلم؟</h3> 
<p id='absec2p'>
    نقوم بتدريبك كمعلم لتواكب التطور التعليمى والتطور الرقمى حتى تستطيع مجابعة التعديات الرقمية ةالتربوية مع طلابك مما يضمن لك ان تكون معلم ناجح ذا شهرة واسعة ومؤشر فى الجيل الجديد
</p>
<NavLink id='absec2but' to='/subscription'>
ابدأ الآن
</NavLink>
  </Col>
  </Col>

 </Contain>
 {/* <Section3/> */}
  </>
  )
}
function Section3(){
  const icons = [
    {icon:sec3Ic1,title:'Get excel',dis:'Create your future!'},
  {icon:sec3Ic2,title:'Sign up for your course',dis:'Subscribe to the package and learn everything new'},
  {icon:sec3Ic3,title:'Find your teacher',dis:'Find the teacher that is right for you and that you want'},
]
  return (
    <section className='absec3-cont'>
     <div className='absec3-sub-cont'>
        <p id='why2'>
OLP educational platform
        </p>
        <h1 id='absec3h1'>
        How OLP Work?
                </h1>
        <div className='how-works-icons '>
         {icons.map((c,i)=><div className='how-icon-item' key={i}>
            <i><img src={c.icon}/></i>
            <h4 id={'absec3h'+i}>{c.title}</h4>
            <span id={'absec3span'+i}>{c.dis}</span>
         </div>)}
         {/* <img src={v1} width='100%' alt=''/> */}
        </div>
     </div>
    </section>
  )
}

export default About
import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import BlueHeader from '../blueHeader';
import { useSelector } from 'react-redux';
import PackageCard from './packageCard';

function StudyPrograms() {
  const { packages } = useSelector(({ packages }) => ({ packages }));

  return (
    <>
      <BlueHeader
        title={'البرامج الدراسية'}
        subTitle={'اشترك الان فى البرنامج الدراسى المناسب لك'}
      />
      <Container fluid style={{marginTop:'100px',marginBottom:'100px'}}>
        <Row>
          {packages.map((packageItem) => {
            if (packageItem.active === true) {
              return (
                <PackageCard key={packageItem._id} packageItem={packageItem} />
              );
            }
            return null; // If package is not active, don't render anything
          })}
        </Row>
      </Container>
    </>
  );
}

export default StudyPrograms;

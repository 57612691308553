import React,{useState,useEffect} from 'react'
import Header from './Header';
import './userDashboard.css';
import SideNav from '../Admin/sideNav.jsx';
import { Outlet,useParams } from 'react-router-dom'
import dashIcon from '../../assets/dashIcon.svg';
// import classManagIcon from '../../assets/classManag.svg';
import {TbMessages} from 'react-icons/tb';
// import {IoIosNotificationsOutline} from 'react-icons/io';
import {CgProfile} from 'react-icons/cg';
import {MdClass} from 'react-icons/md';
import MobileNave from './mobileNave'
import logOut from '../../assets/logout.svg';
import GetText from '../translation/getText.jsx'
import { useDispatch } from 'react-redux';
import { Get_Materials } from '../../actions/curriculums/materials';
import { Inetialize_Levels } from '../../actions/curriculums/levels.js';

function UserDashboard({user}) {
  const params = useParams();
  // console.log(params  )
  const taps = [
    {
      name: "فصلى", path: "" ,icon:<MdClass />},
      {name:'الرسائل',path:'messages',icon:<TbMessages/>},
      {name:'حسابى ',path:'profile',icon:<CgProfile/>},
]


const adminTaps = [
  {name:"الاشتراكات",path:""},
  {name:"المستويات",path:"new-level"},
  {name:"المواد",path:"materials"},
  {name:"البرامج", path:"packages"},
  {name:"الفصول", path:"classes"},
  // {name:"المعلمون",path:"teachers-list"},
  {name:"الطلاب",path:"students"},
  {name:'الرسائل',path:'messages',icon:<TbMessages/>},


  ]

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const dispatch = useDispatch();

  useEffect(()=>{
    if(user.role==='Admin'){
      dispatch(Get_Materials());
      dispatch(Inetialize_Levels());
    }
  },[])
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className='user-dashboard'>
       <div className='user-dash-header'>
        <Header menuColor={'#333'}/>
        </div>
        <div className='user-dash-body'>
            <div className='subhead public_bg'>
              <img src={dashIcon} alt='dashIcon'/>
                <span id='suh1'>
                    Dashboard
                </span>
            </div>
            <div className='controlmenu'>
{windowSize.width>900?<SideNav taps={user.role==='Admin'?adminTaps:taps}/>:
<MobileNave taps={user.role==='Admin'?adminTaps:taps}/>
}            </div>
            <div id='sub-body'>
 <Outlet/> 
            </div>
        </div>
    </div>

  )
}

export default UserDashboard

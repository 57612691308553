import React, { useState, useEffect,useCallback } from "react";
// save text arr to store in reducer translation arabic
import { Collect_Text } from "../../actions/translation.js";
// redux hooks
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
function GetText({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const elementTypes = [
    "span",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "button",
    "a",
    "input",
    "textarea",
    "label",
    "legend"
  ];

  const [language, setLanguage] = useState("ar"); // add any number of languages.
  //-------- insert translation--------
  const { translation} = useSelector(mapStateFromProps);

  const [translated, setTranslated] = useState(false);

  const translate =async () => {
    try{
     const lang = translation.lang;
     const current = translation.current;
     const ids =await Object.keys(lang);
     if(current==='ar'){
       document.body.style.direction='rtl';
       // document.body.style.transform= 'scaleX(-1)';
      }
      else{
        document.body.style.direction='ltr'
      }
      const original = 'en'
      await lang&&ids.forEach((id)=>{
       if(lang[id].ar===null||"")return;
       const ele = document.querySelector(`#${id}`)
      //  if(id==='mobnav0'){console.log(ele)}
      //  console.log('ids',id)
       if(!ele)return;
       if(ele){
         ele.innerHTML=lang[id][current];
       }
       })

    //  if(current===original) {};
      }catch(e){console.log(e.message)}
  };
  //--------collect text----------
  const collectText = async (elementT) => {
    try {
      const lang = translation.lang.name;
      const type = translation.lang.type;
      //element parameter refer to element name
      const textArr = [];
      await elementT.forEach((t) => {
        const elements = document.body.querySelectorAll(t);
        elements.forEach((e, i) => {
          if(!e.id) return;  
          if (t === "input" || t === "textarea") {
            const ele = e.placeholder.trim().toLowerCase();
            return textArr.push({id:e.id,en:ele,ar:null,type:t});
          }
          const ele = e.innerHTML.trim();
          if ((typeof parseInt(ele) === "number") & (parseInt(ele) > 0)) {
            return;
          }
          if (
            ele === " " ||
            ele === "" ||
            ele
              .split("")
              .includes(
                "@" || ele.includes(".com") || ele.split("/").includes("http:")
              )
          )
            return;
          if (!ele.split("").includes("<") & e.id!=='') {
            textArr.push({id:e.id,en:ele,ar:null,type:t});
           
          }
        });
      });

            await dispatch(Collect_Text( textArr));
   
    } catch (e) {
      console.log(e);
    }
  };
  // useEffect(() => {
  //   translate();
  // }, [children,translation, location]);

  // useEffect(() => {
  //   setTimeout(()=>{
  //     collectText(elementTypes);
  //   },3000) 
  // }, [location]);
  return <>{children}</>;
}

export default GetText;
function mapStateFromProps({ translation }) {
  return { translation };
}
